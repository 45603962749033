export default {
  isCurrentPlayer(state) {
    return state.currentPlayer === true
  },
  isLoading(state) {
    return state.loading === true
  },
  battleMessage(state) {
    return state.battleMessage
  },
  cardsSelected(state) {
    let cardsSelected = state.player.board.filter(card => card.selected)
    let cardsSelected2 = state.events.filter(card => card.selected)
    if(cardsSelected.length > 0){
      return cardsSelected
    } else if (cardsSelected2.length>0) {
      return cardsSelected2
    } else {
      return []
    }
  },
  currentCardSelected(state, getters) {
    if (getters.cardsSelected.length > 0) {
      const cardsSelected = getters.cardsSelected[0]
      if (cardsSelected.genre === 'genre-event') {
        return '#card-event'
      }
      // Nel caso di board card normale
      const cardId = `#card-${getters.cardsSelected[0].id}`
      return cardId
    }
    return null
  },
  loaded(state) {
    return state.loaded_data === true && state.loaded_images === true
  },
  playerFromRole(state) {
    return function(role) {
      return state[role]
    }
  },
  // ragionamento da spostare in ruby
  // attualmente viene usato da una mutazione
  // il che rende ancora + strana la sua posizione in questo luogo
  playerFromId(state) {
    return function search(id) {
      if (state.player.id === id) return state.player
      if (state.opponent.id === id) return state.opponent
      return null
    }
  },
  playerLife(state) {
    return state['player'].life
  },
  opponentLife(state) {
    return state['opponent'].life
  }
}
