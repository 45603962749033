<template lang="pug">
  .card(style="position: absolute; left: 10px; top: 80px; max-width: 200px; z-index: 2000")
    .card-header(@click="open = !open" style="cursor: pointer")
      | Storia
    .card-body(v-if="this.open")
      .notice-container
        transition-group(name="fadeDown" tag="ul" class="list-unstyled mb-0")
          li.msg(v-html="battleAction.message"
                  v-for="battleAction in battleActions"
                   :key="battleAction.id")
    .card-footer(v-if="this.open")
      small(v-if="currentPlayer")
        | È tuo il turno
      small(v-else)
        | È il turno del tuo avversario
</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
    return {
      open: false
    }
  },
  computed: mapState(['battleActions', 'currentPlayer'])
}
</script>


<style lang="sass">
  .notice-container
    font-size: 80%
    max-height: 140px
    overflow-x: auto
</style>
