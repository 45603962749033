<template lang='pug'>
  .bg-label(:style="size")
    .bg-label-content {{text}}
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    width: { type: Number, default: 300 }
  },
  computed: {
    size() {
      return `width: ${this.width}px;
              height: ${this.height}px;
              padding-top: ${this.height / 3}px;
              margin: 0 auto`
    },
    height() {
      return this.width / 3
    }
  }
}
</script>

<style lang="sass" scoped>
.bg-label
  background-image: url('/card/label.png')
  background-size: cover
  background-position: center

  .bg-label-content
    color: white
    text-align: center
    &:hover
      text-decoration: none !important
</style>
