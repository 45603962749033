<template lang="pug">
  #player-board(@click='deselectCard()')
    transition-group.scroll-x.pb-2.px-3(name='fade'
                          v-dragscroll="true"
                          v-on:leave="leave"
                          tag='div'
                          v-intro="'Questo è il posto dove schieri le tue carte'")
      player-board-card(v-for="(card, index) in this.$store.state.player.board"
                        player-role="player"
                        deck='board'
                        :card="card"
                        :position="index"
                        :key="card.id"
                        :id="'card-'+card.id"
                        class='fade-item')
</template>

<script>
import { TweenLite, Linear } from 'gsap'
import PlayerBoardCard from './player_board_card.vue'
import { mapGetters } from 'vuex'

export default {
  components: { PlayerBoardCard },
  computed: {
    ...mapGetters(['cardsSelected', 'isCurrentPlayer']),
    checkSelectedCard() {
      let selectedCards = this.cardsSelected
      return selectedCards.length > 0 && selectedCards[0].genre == 'genre-creature'
    }
  },
  methods: {
    leave(el) {
      TweenLite.to(el, 0.5, { scale: 2, ease: Linear.easeOut });
    },
    deselectCard(){
      if(this.isCurrentPlayer && this.checkSelectedCard) {
        this.$store.dispatch('toggleSelectForAttack', {
          id: this.cardsSelected[0].id
        })
      }
    }
  }
}
</script>

<!-- https://forum.vuejs.org/t/transition-for-a-list-of-components/9759/8 -->
<style lang="sass">
  #player-board
    position: absolute
    top: 320px
    width: 100%
    text-align: center
    .scroll-x
      overflow-x: auto
      white-space: nowrap
      min-height: 174.5px
      padding-top: 10px
    .fade-move
      transition: all 0.5s
    .fade-item
      display: inline-block
    .fade-leave-active
      position: absolute
</style>
