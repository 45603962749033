<template lang="pug">
transition(name="fade")
  #card-message.bg-gray-2.bordo-bello.py-2(v-if="battleMessage")
    | {{this.battleMessage}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isCurrentPlayer', 'battleMessage']),
  },
  methods: {
    clearBattleMessage() {
      this.$store.commit('clearBattleMessage')
    }
  },
  watch: {
    battleMessage(newValue, oldValue) {
      if (newValue != oldValue) {
        const _this = this
        setTimeout(function() {
          if (newValue == _this.battleMessage) {
            _this.clearBattleMessage()
          }
        }, 2000);
      }
    }
  }
}
</script>

<style lang="sass">
#card-message
  position: absolute
  z-index: 1001
  top: 50%
  left: 50%
  width: 200px
  text-align: center
  margin-top: -12px
  margin-left: -100px
  border-radius: 5px
  padding: 5px
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, .5)

</style>
