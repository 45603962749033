<template lang="pug">
div
  img(:src="this.card.imgCardPath" 
      class="card-img-top" 
      style='max-height: 100px; align: center; object-fit: cover;')
  theme(:icon='this.card.themeIcon')
  mana(:cost="this.card.manaCost")
  .card-body.p-0(style="position: relative; width: 130px")
    .text-left(style="position: absolute; top: 10px; left: 10px")
      img.img-align-for-icon(:src="this.card.imgGenrePath" height="13" style="margin-right: 2px")
      small {{this.card.name}}
      p(v-if="this.card.rules")
        span(v-for="rule in this.card.rules")
          small
            img.img-align-for-icon(:src="rule.icon" :height='10')
            span {{rule.value}}
    div(v-if="this.card.genre != 'genre-event'")
      div(style="position: absolute; left: 10px; top: 71px")
        small.mr-1 {{this.card.strength}}
        img.img-align-for-icon(src="/broadsword.svg" :height='10')
      div(style="position: absolute; right: 0px; top: 71px")
        small.mr-1 {{this.card.constitution}}
        img.img-align-for-icon(src="/shield.svg" :height='10')
</template>

<script>
import Mana from './mana.vue'
import Theme from './theme.vue'

export default {
  components: { Mana, Theme },
  props: {
    card: { required: true, type: Object }
  }
}
</script>
