<script>
import { mapGetters } from 'vuex'

import boardCardTemplate from './mixins/board_card_template.vue'
import bounceAnimation from '../mixins/bounce_animation'
import cardStyle from './mixins/card_style'
import cardOpacity from '../mixins/card_opacity'
import player from './mixins/player'
import highlight from '../mixins/highlight'
import sounds from '../mixins/sounds'

export default {
  mixins: [
    boardCardTemplate,
    bounceAnimation,
    cardOpacity,
    cardStyle,
    highlight,
    player,
    sounds,
  ],
  computed: {
    cardAndBackgroundStyle() {
      return {
        opacity: this.opacity,
        'background-image': `url('${this.card.imgCardBoard}')`,
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'background-size': '100x150',
        'cursor': 'pointer'
      }
    },
    stateEffects() {
      if (this.isCurrentPlayer === false) {
        return 'grayscale'
      }
      if (this.card.attacked === true) {
        return 'grayscale'
      }
      // bisogna preparare il dato sul server questo è buggato al cambio di
      // position della card
      return ''
    },
    ...mapGetters(['isCurrentPlayer'])
  },

  methods: {
    cardClicked() {
      this.bounce(this.$refs.card)
      this.hideMagnify()
      if (this.isCurrentPlayer && this.card.clickable) {
        this.selectCard()
      } else {
        this.$store.dispatch('forbiddenClickOnCard', this.message)
      }
    },
    selectCard() {
      const eventsInGame = this.$store.state.events.length
      if (eventsInGame > 0) {
        this.$store.dispatch('eventOnCard', {
          targetCardId: this.card.id
        })
      } else {
        this.$store.dispatch('toggleSelectForAttack', {
          id: this.card.id
        })
      }
    },
    mouseover(e){
      this.showMagnify()
    },
    mouseout(e){
      this.hideMagnify()
    },
    touchStart(e){
      this.showMagnify()
    },
    touchEnd(e){
      this.hideMagnify()
    },
    calcX(){
      const el = this.$el
      const parentNode = el.parentNode
      const elX = el.offsetLeft 
      const parentElX = parentNode.offsetLeft
      return elX + parentElX
    },
    calcY(){
      const el = this.$el
      const parentNode = el.parentNode
      const elY = el.offsetTop
      const parentElY = parentNode.offsetTop + parentNode.parentNode.offsetTop
      const result = elY + parentElY 
      return result
    },
    showMagnify(){
      this.$store.dispatch('magnifyOn', {
        el: this.$el,
        card: this.card,
        posX: this.calcX(),
        posY: this.calcY(),
        position: 'top'
      })
    },
    hideMagnify(){
      this.$store.dispatch('magnifyOn', { card: null })
    }
  },
}
</script>
