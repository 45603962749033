<template lang='pug'>
  a.shop-card-container(:href='url')
    .card-frame(:style='frameStyle')
      .card-title.text-white {{ title }}
      .card-text.text-white {{ text }}
    .card-image(:style='cardBackgroundImage')

</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    image: { type: String, required: true },
    width: { type: Number, default: 300 },
    title: {type: String, required: true },
    text: {type: String, required: true }
  },
  computed: {
    cardBackgroundImage() {
      return `background-image: url('${this.image}');
              ${this.imgWidthStyle};
              ${this.imgHeightStyle};`
    },
    frameStyle() {
      return `background-image: url('/transformed/t_01-600x600.png');
              ${this.frameWidthStyle};
              ${this.frameHeightStyle};`
    },
    height() {
      return this.width*1.5
    },
    frameWidthStyle() {
      return `width: ${this.width}px`
    },
    frameHeightStyle() {
      return `height: ${this.height}px`
    },
    imgWidthStyle() {
      return `width: ${this.width-20}px`
    },
    imgHeightStyle() {
      return `height: ${this.width-20}px`
    }
  }
}
</script>

<style lang="sass">
.shop-card-container
  display: block
  width: 300px
  height: 450px
  position: relative
  margin: 0 auto
  filter: drop-shadow(0px 0px 10px #000000)
  
  .card-frame
    position: relative
    background-size: cover
    background-position: center
    width: 120px
    height: 178px

    z-index: 100
    
    .card-title
      position: absolute
      top: 220px
      bottom: 0
      left: 0
      right: 0
      height: 20px
      width: 280px
      text-align: center
      margin: 0 auto
    .card-text
      position: absolute
      top: 280px
      bottom: 0
      left: 0
      right: 0
      height: 20px
      width: 180px
      text-align: left
      margin: 0 auto

  .card-image
    top: 30px
    position: absolute
    background-size: cover
    background-position: center
    width: 100px
    height: 100px

</style>
