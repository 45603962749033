/* global App */
/* global location */
import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

Vue.use(Vuex)


// Usato al momento soprattutto per highlight
function broadcastToOpponent(action, mutation) {
  // In questo caso viene evocato (lato rails)
  // il metodo BattleChannel#broadcastToOpponent
  App.battleChannel.perform('broadcastToOpponent', {
    type: action.type,
    mutation,
    payload: action.payload
  })
}


function createWebSocketPlugin(socket) {
  return (store) => {
    // SEND ACTIONS TO SERVER
    store.subscribeAction((action) => {
      if (action.type !== 'cardMouseOver' &&
          action.type !== 'magnifyOn' &&
          action.type !== 'highlightToOpponent') {
        console.log('---------------------')
        console.log(action.type)
      }
      switch (action.type) {
        case 'displayMessage':
          broadcastToOpponent(action, 'displayOpponentMessage')
          break
        case 'highlightToOpponent':
          broadcastToOpponent(action, 'toggleOpponentHighlightedCard')
          break
        case 'retire':
          App.battleChannel.perform('retire')
          break
        case 'setupInterval':
          break
        case 'magnifyOn':
          break
        default:
          // In questo caso viene evocato (lato rails)
          // il metodo BattleChannel#receive
          App.battleChannel.send({
            type: action.type,
            payload: action.payload
          })
      }
    })
  }
}


export default function setupStore() {
  const socketCable = App.cable
  const plugin = createWebSocketPlugin(socketCable)

  const store = new Vuex.Store({
    plugins: [plugin],
    state,
    mutations,
    getters,
    actions
  })
  return store
}
