<template lang="pug">
div
  b-modal(ref='messagesModal'
          hide-footer
          hide-header
          body-bg-variant="primary"
          content-class="shadow bg-transparent")
      
      p.text-center
        a(@click.prevent="sendMessage('Te dago!')"
          style="cursor: pointer") Te dago!
      p.text-center
        a(@click.prevent="sendMessage('Te speto fora!')"
          style="cursor: pointer") Te speto fora!
      p.text-center
        a(@click.prevent="sendMessage('Te stonfo de tonae!')"
          style="cursor: pointer") Te stonfo de tonae!
      p.text-center
        a(@click.prevent="sendMessage('Bea more')"
          style="cursor: pointer") Bea more
      p.text-center
        a(@click.prevent="sendMessage('Bea zogada')"
          style="cursor: pointer") Bea zogada
      p.text-center
        a(@click.prevent="sendMessage('Ciao cocco')"
          style="cursor: pointer") Ciao cocco

  span
    small(@click="clicked"
         style="cursor: pointer") Cantagliele
    transition(name='bounce')
      .container-message(v-if='message' :style='containerStyle')
        h3 {{this.message}}

</template>

<script>
export default {
  methods: {
    clicked() {
      this.$refs['messagesModal'].show()
    },
    sendMessage(playerMessage) {
      this.$refs['messagesModal'].hide()
      this.$store.dispatch('displayMessage', playerMessage)
    }
  },
  computed: {
    containerStyle() {
      return { 
        'bottom': '30px', 
        'background-image': 'url("/chat-bubble.png")', 
        'padding-top': '56px'
      }
    },
    message() {
      return this.$store.state.player.message
    },
  },
  watch: {
    message(newValue, oldValue){
      if(newValue != oldValue) {
        setTimeout(() => {
          this.$store.commit('displayPlayerMessage', null)
        }, 3000)
      }
    }
  }
}
</script>

<style lang='sass'>
.container-message
  position: absolute
  z-index: 1000
  background-size: cover
  right: -370px
  color: #000000
  width: 236px
  height: 189px
  padding-left: 10px
  padding-right: 10px
</style>
