export default {
  data() {
    return {
      money: 0,
      fontSize: 0,
      tweenedMoney: 0,
      tweenedFontSize: 0
    }
  },
  computed: {
    animatedMoney() {
      return this.tweenedMoney.toFixed(0)
    },
    cssFontSize() {
      return `font-size:${this.tweenedFontSize}px`
    }
  },
  methods: {
    setMoney(moneyAdd) {
      this.setFontSize()
      this.setMoneyQuantity(moneyAdd)
    },
    setFontSize() {
      this.fontSize = 30
    },
    setMoneyQuantity(moneyAdd) {
      this.money = moneyAdd
    }
  },
  watch: {
    money(newValue) {
      TweenLite.to(this.$data, 7, {
        tweenedMoney: newValue,
        ease: Elastic.easeOut
      })
    },
    fontSize(newValue) {
      TweenLite.to(this.$data, 2, {
        tweenedFontSize: newValue,
        ease: Back.easeOut
      })
    }
  }
}
