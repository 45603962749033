<template lang="pug">
div
  span
    transition(name='bounce')
      .container-message(v-if='opponentMessage' :style='containerStyle')
        h3 {{this.opponentMessage}}

</template>

<script>
export default {
  computed: {
    containerStyle() {
      return {
        'top': '30px', 
        'background-image': 'url("/chat-opponent-bubble.png")', 
        'padding-top': '80px' 
      }
    },
    opponentMessage() {
      return this.$store.state.opponent.message
    }
  },
  watch: {
    opponentMessage(newValue, oldValue){
      if(newValue != oldValue) {
        setTimeout(() => {
          this.$store.commit('displayOpponentMessage', null)
        }, 3000)
      }
    }
  }
}
</script>

<style lang='sass'>
.container-message
  position: absolute
  z-index: 1000
  background-size: cover
  right: -370px
  color: #000000
  width: 236px
  height: 189px
  padding-left: 10px
  padding-right: 10px
</style>
