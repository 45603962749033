<template lang='pug'>
transition(name="fade")
  div(v-if="loaded")
    .bordo-bello.mt-5.mx-auto(style="max-width: 360px")
      transition-group(name="fade")
        .p-3(v-for="user in users" :key="user.id") {{user.name}}
    .mx-auto.mt-4.text-center(style="max-width: 660px")
      a.mr-2.btn.btn-danger(@click.prevent='createBattle'
                            href='#'
                            v-if="currentUserIsOwner && opponentIsPresent"
                            data-disable-with="Bea more..." ) Gioca
      a.mr-2.btn.btn-warning(@click.prevent='joinBot'
                            href='#'
                            v-if="ownerCanAddBot") Aggiungi Bot
      a.btn.btn-warning(@click.prevent='removeBot'
                        href='#'
                        v-if="ownerCanRemoveBot") Rimuovi Bot
      a.btn.btn-warning(@click.prevent='removeUser'
                        href='#'
                        v-if="currentUserIsGuest") Abbandona
    .mx-auto.mt-3.text-center
      a.text-muted(@click.prevent='deleteMatch' href='#' v-if="currentUserIsOwner") Annulla
</template>

<script>
import { Howl } from 'howler'
import lobbyActions from './lobby-actions'
export default {
  mixins: [lobbyActions],
  data() {
    return {
      channel: undefined,
      currentUser: null,
      users: [],
      sound: undefined,
      loaded: false
    }
  },
  computed: {
    currentUserIsOwner() {
      if (this.currentUser && this.users[0]) {
        return (this.currentUser.id == this.users[0].id)
      }
      return false
    },
    currentUserIsGuest() {
      if (this.currentUser && this.users[0]) {
        return (this.currentUser.id != this.users[0].id)
      }
      return false
    },
    matchId() {
      return this.$route.params.matchId
    },
    ownerCanAddBot() {
      return (this.currentUserIsOwner && this.opponentIsBlank)
    },
    ownerCanRemoveBot() {
      return (this.currentUserIsOwner && this.opponentUserIsBot)
    },
    opponentUserIsBot() {
      return (this.opponentIsPresent && this.users[1].type == 'User::Bot')
    },
    opponentIsPresent() {
      return (this.users.length == 2)
    },
    opponentIsBlank() {
      return (this.users.length < 2)
    }
  },
  methods: {
    getUsers() {
      $.ajax({
        url: `/matches/${this.matchId}.json`
      }).done(msg => {
        this.currentUser = msg.current_user
        this.users = msg.users
      }).fail(response => {
        if(response.status === 404){
          this.$router.push({ name: 'matches' })
        } else {
          console.log('FALLITO!', response)
        }
      }) 
    },
    refreshUsers(data) {
      this.users = data.users
    },
    setupSoundtrack() {
      this.sound = new Howl({
        src: ['/audio/music/poenta+bacala.ogg'],
        autoplay: true,
        loop: true,
        volume: this.$store.state.volume,
        onplayerror: function() {
          this.sound.once('unlock', function() {
            this.sound.play();
          });
        }
      });
      this.sound.play()
    },
    createChannel() {
      const _this = this
      const channel = App.cable.subscriptions.create({
        channel: 'MatchChannel',
        match: this.matchId
      }, {
        initialized() {
          _this.loaded = true
        },
        connected() {
          _this.loaded = true
        },
        received(data) {
          _this.loaded = true
          if (data.status === 'match destroy') {
            _this.channel.unsubscribe()
            _this.$router.push({name: 'matches'})
          } else if (data.status === 'match start') {
            _this.channel.unsubscribe()
            _this.goToBattle(data)
          } else if (data.status === 'join create') {
            _this.refreshUsers(data)
          } else if (data.status === 'join destroy') {
            _this.refreshUsers(data)
          } else {
            alert('Cari Ruybnetti, abbiamo problemi. Verificate in lobby.vue')
            console.log(data)
            // $.get(`${window.location}.js`)
          }
        }
      })
      return channel
    }
  },
  mounted() {
    this.setupSoundtrack()
    this.getUsers()
    if (this.channel !== undefined) {
      this.loaded = true
    } else {
      this.channel = this.createChannel()
    }
  },
  destroyed() {
    this.sound.fade(1, 0, 2000)
  }
}
</script>
