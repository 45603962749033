<template lang="pug">
.disable-select
  div(style="position: absolute; color: #a00" v-intro="tutorialInfo")
    strong.mr-2(ref="points") {{ animatedPoints }}
    span.fas.fa-heartbeat(ref="icon")
</template>

<script>
import sounds from '../mixins/sounds'
import visualFx from '../mixins/visual_fx'

export default {
  props: {
    // i ruoli possono essere player oppure opponent
    role: {type: String, required: true}
  },
  mixins: [sounds, visualFx],
  watch: {
    points(newPoints, oldPoints) {
      this.soundsFx(newPoints, oldPoints)
      this.visualFx_setup(newPoints, oldPoints)
    }
  },
  methods: {
    soundsFx(newPoints, oldPoints) {
      if (newPoints > oldPoints) {
        this.playAudioDefense()
      } else {
        this.playAudioOffense()
      }
    }
  },
  computed: {    
    tutorialInfo(){
      if (this.role == 'player'){
        return `Sono i tuoi <strong class='text-danger'>punti vita</strong>.<br>
                Se arrivano <strong class='text-danger'>a 0 hai perso</strong>.`
      }
      if (this.role == 'opponent') {
        return `Sono i <strong class='text-danger'>punti vita</strong> del tuo avversario.<br>
                Se arrivano <strong class='text-danger'>a 0 hai vinto</strong>.`
      }
    }
  }
}
</script>
