<template lang="pug">
  transition(appear name="fade")
    span#card-event.card-event.selected.card-board.card.text-white(@mouseenter="showMagnify"
                                                        @mouseleave="hideMagnify")
      img(:src="this.card.imgCardPath" class="rounded")
      .card-little-container.p-3.text-center
        small(v-for="rule in this.card.rules")
          span.disable-select.bg-dark.rounded-circle.p-2
            img(:src="rule.icon" height='20')
            span {{rule.value}}
          br
</template>

<script>

export default {
  props: {
    card: { type: Object, required: true },
  },
  methods: {
    calcX(){
      const el = this.$el
      return el.offsetLeft  + el.parentNode.offsetLeft
    },
    calcY(){
      const cardEvent = this.$el
      const el = cardEvent
      const result = el.offsetTop +
                     el.clientHeight +
                     el.parentNode.parentNode.offsetTop
      return result
    },
    showMagnify(){
      this.$store.dispatch('magnifyOn', {
        el: this.$el,
        card: this.card,
        posX: this.calcX(),
        posY: this.calcY(),
        position: 'bottom'
      })
    },
    hideMagnify(){
      this.$store.dispatch('magnifyOn', { card: null })
    },
  }
}
</script>


<style scoped lang="sass">
  .card-event
    position: absolute
    top: -70px
  .card-board
    display: flex
    transition: box-shadow 0.3s ease-in-out
    z-index: 1
    img
      object-fit: cover
      max-height: 100%
    .card-little-container
      position: absolute
      bottom: 0
      display: block
      width: 100%
  .selected
    box-shadow: 0 0 10px 7px #007BFF
</style>
