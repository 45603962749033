<template lang="pug">
span.float-right
  i.fas.fa-cog(@click="pause"
               style="cursor: pointer")
      
  b-modal(ref='pauseModal'
          hide-footer
          title="Menù di pausa"
          header-bg-variant="primary"
          body-bg-variant="primary"
          content-class="shadow bg-transparent")
      
      p.text-center
        a.text-success(v-if="isFullscreen"
          @click.prevent="closeFullscreen"
          style="cursor: pointer") Fullscreen ON
        a(v-else
          @click.prevent="openFullscreen"
          style="cursor: pointer") Fullscreen OFF
      p.text-center
        a.text-success(v-if="$store.state.sound"
          @click.prevent="soundOff"
          style="cursor: pointer") Suoni ON
        a(v-else
          @click.prevent="soundOn"
          style="cursor: pointer") Suoni OFF
      p.text-center
        a(@click.prevent="retire"
          style="cursor: pointer") Ritirati
      p.text-center
        a(@click.prevent="backToGame"
          style="cursor: pointer") Torna al gioco
</template>

<script>
  import { isMobile } from 'mobile-device-detect'
  
  export default {
    data() {
      return {
        isFullscreen: document.fullscreen
      }
    },
    created(){
      document.addEventListener('keydown', (evt) => {
        if (evt.keyCode === 27) {
          this.toggleMenu()
        }
      })
    },
    methods: {
      backToGame() {
        this.$refs['pauseModal'].hide()
      },
      closeFullscreen() {
        document.exitFullscreen()
                .then(() => this.isFullscreen = false)
                .catch((err) => console.log(err))
        if (isMobile) { screen.orientation.lock('landscape') }
      },
      openFullscreen() {
        const element = document.documentElement
        element.requestFullscreen()
               .then(() => this.isFullscreen = true)
               .catch(err => {
                  console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
               })
        if (isMobile) { screen.orientation.lock('landscape') }
      },
      soundOff() {
        this.$store.state.sound = false
      },
      soundOn() {
        this.$store.state.sound = true
      },
      pause() {
        this.$refs['pauseModal'].show()
      },
      retire() {
        this.$refs['pauseModal'].hide()
        this.$store.dispatch('retire')
      },
      setupFullscreenListener() {
        document.addEventListener('fullscreenchange', (event) => {
          // document.fullscreenElement will point to the element that
          // is in fullscreen mode if there is one. If not, the value
          // of the property is null.
          if (document.fullscreenElement) {
            this.isFullscreen = true
          } else {
            this.isFullscreen = false
          }
        })
      },
      toggleMenu() {
        if (this.$refs['pauseModal'].isHidden) {
          this.$refs['pauseModal'].show()
        } else {
          this.$refs['pauseModal'].hide()
        }
      }
    },
    mounted() {
      this.isFullscreen = document.fullscreenElement != null
      this.setupFullscreenListener()
    },
  }
</script>
