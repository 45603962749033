<template lang="pug">
  .card-img-overlay(style="height: 100px")
    .float-right.margin-mana(style="background-color: rgba(0,0,0,0.5)")
      i.fas.fa-money-bill-alt
      span.pl-2 {{ cost }}
</template>

<script>
export default {
  props: {
    cost: {
      required: true,
      type: Number
    }
  }
}
</script>
