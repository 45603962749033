export default {
  attackOpponent(context) {
    context.commit('setLoading')
  },
  attackOpponentCard(context) {
    context.commit('setLoading')
  },
  highlightToOpponent(context, data) {
    context.commit('togglePlayerHighlightedCard', data)
    // context.state.magnifyEnabled = true
  },
  magnifyOn(context, data) {
    context.commit('setMagnifyData', data)
  },
  displayMessage(context, data) {
    context.commit('displayPlayerMessage', data)
  },
  draw() {},
  eventOnCard(context) {
    context.commit('setLoading')
  },
  eventOnPlayer(context) {
    context.commit('setLoading')
  },
  forbiddenClickOnCard(context, data) {
    context.commit('setBattleMessage', data)
  },
  forbiddenClickOnPlayer(context, data) {
    context.commit('setBattleMessage', data)
  },
  pass(context) {
    context.commit('setLoading')
    context.commit('timerClear')
  },
  play(context) {
    context.commit('setLoading')
  },
  retire(context) {
  },
  selectForDefense(context, data) {
    context.commit('setLoading')
    context.commit('addToNotification', data)
  },
  setupInterval(context) {
    context.commit('timerClear')

    const timerInterval = setInterval(() => {
      const timerData = context.state.timer
      if (timerData.remaining_time > 0) {
        context.commit('timerDecrease')
      } else {
        console.log('TIME OUT')
        context.commit('timerClear')
      }
    }, 1000)
    context.commit('timerIntervalSet', timerInterval)
  },
  timeOut() {},
  toggleSelectForAttack(context) {
    context.commit('setLoading')
  }
}
