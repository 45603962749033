<template lang="pug">
  #opponent-board(@click='deselectCard()')
    transition-group(name='fade'
                     v-dragscroll="true"
                     v-on:leave="leave"
                     tag='div'
                     style="min-height: 188.5px;"
                     v-intro="'Qui il tuo avversario pone le sue carte'")
      opponent-board-card(v-for="(card, index) in this.$store.state.opponent.board"
                          player-role="opponent"
                          deck='board'
                          :card="card"
                          :position="index"
                          :key="card.id"
                          class='fade-item')
</template>

<script>
import OpponentBoardCard from './opponent_board_card.vue'
import { mapGetters } from 'vuex'

export default {
  components: { OpponentBoardCard },
  computed: {
    ...mapGetters(['cardsSelected', 'isCurrentPlayer']),
    checkSelectedCard() {
      let selectedCards = this.cardsSelected
      return selectedCards.length > 0 && selectedCards[0].genre == 'genre-creature'
    }
  },
  methods: {
    leave(el) {
      TweenLite.to(el, 2, {scale: 2, ease: Linear.easeOut});
    },
    deselectCard(){
      if (this.isCurrentPlayer && this.checkSelectedCard) {
        this.$store.dispatch('toggleSelectForAttack', {
          id: this.cardsSelected[0].id
        })
      }
    }
  }
}
</script>

<style lang="sass">
  #opponent-board
    position: absolute
    top: 100px
    width: 100%
    text-align: center
    .scroll-x
      overflow-x: auto
      white-space: nowrap
    
    .fade-move
      transition: all 1s
    .fade-item
      display: inline-block
    .fade-leave-active
      position: absolute
</style>
