<template lang="pug">
  div
    updateMousePosition
    transition(name="fade")
      #magnify.shadow-lg.card(v-if="visible" 
                              ref="infoBox"
                              v-bind:style="{ left: this.xPos+'px', top: this.yPos+'px' }")
        .card-body(v-if="magnifyData.card != null")
          strong
            p {{magnifyData.card.name}}
          p(v-for="rule in magnifyData.card.rules")
            img(:src="rule.icon" height='20')
            | {{rule.description}}
          p
            | Tema
            img(:src='magnifyData.card.themeIcon' height='20')
            span  {{magnifyData.card.theme.name}}
          p
            em {{magnifyData.card.poetic}}
          p
            img.mr-1(src="/icons/money-stack.svg" height='20')
            | da pagar:
            | {{magnifyData.card.manaCost}}
</template>

<script>
import { mapState } from 'vuex'
import UpdateMousePosition from './update_mouse_position'

export default {
  components: {
    UpdateMousePosition
  },
  data(){
    return {
      distanceFromMouse: 20,
      visible: false,
      xPos: 0,
      yPos: 0
    }
  },
  computed: {
    ...mapState({
      magnifyData: 'magnify',
      magnifyEnabled: 'magnifyEnabled'
    })
  },
  methods: {
    calculateY(){
      const margin = 20
      if(this.magnifyData.position === 'top') {
        const infoBoxHeight = this.$refs.infoBox.offsetHeight
        return this.magnifyData.posY - infoBoxHeight - margin
      } else {
        return this.magnifyData.posY + margin
      }
    }
  },
  watch: {
    magnifyData(newData, oldData){
      if (this.magnifyData == null) {
        this.visible = false
        return
      } 
      if (this.magnifyData.card == null) {
        this.visible = false
        return
      }      
      this.visible = true
      this.$nextTick(function(){
        this.xPos = this.magnifyData.posX
        this.yPos = this.calculateY()
      })
    }
  }
}
</script>

<style lang="sass">
#magnify
  position: absolute
  z-index: 100
  max-width: 300px
</style>
