// define a mixin object
export default {
  props: {
    deck: { type: String, required: true },
  },
  methods: {
    highlightData(state){
      return {
        id: this.card.id,
        state: state,
        deck: this.deck,
        // La proprietà *player*
        // deriva solitamente
        // dal mixins player.js
        playerId: this.player.id,
      }
    },
    highlight(e) {
      this.$store.dispatch('highlightToOpponent', this.highlightData(true))
      this.popOverShow = true // TODO: <- si può togliere?
    },
    dehighlight(e) {
      this.$store.dispatch('highlightToOpponent', this.highlightData(false))
      this.popOverShow = false // TODO: <- si può togliere?
    }
  }
}
