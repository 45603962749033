<template lang="pug">
div
  img(src="/gondola.png"
      ref='gondola'
      :style='moveGondola()')
  .progress.bg-info(:style="progressStyle")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      gondolaWidth: 100,
      progressWidth: 600,
      scaleX: 1,
      lengthForGondola: 0,
      proportionForDecrease: 0,
      proportionalDecrease: 0,
      left: 0,
    }
  },
  methods: {
    calcDecrease() {
      this.lengthForGondola = this.progressWidth - this.gondolaWidth
      this.proportionForDecrease = this.timerRange / this.lengthForGondola
      this.proportionalDecrease = this.counter / this.proportionForDecrease
    },
    applyDecrease() {
      if (this.$store.getters.isCurrentPlayer) {
        this.scaleX = 1
        this.left = this.lengthForGondola - this.proportionalDecrease
      } else {
        this.scaleX = -1
        this.left = this.proportionalDecrease
      }
    },
    moveGondola() {
      this.calcDecrease()
      this.applyDecrease()
      return `position: absolute;
              width: ${this.gondolaWidth}px;
              top: -28px;
              left: ${this.left}px;
              transition: all 1s;
              transform: scaleX(${this.scaleX});`
    },
  },
  computed: {
    progressStyle() {
      return {
        width: `${this.progressWidth}px`
      }
    },
    ...mapGetters(['isCurrentPlayer']),
    ...mapState({
      counter: state => state.timer.remaining_time,
      timerRange: state => state.timer.range
    })
  },
  watch: {
    isCurrentPlayer() {
      this.$store.dispatch('setupInterval')
    }
  }
}
</script>

<style lang='sass'>
.progress
  height: 2px !important
.progress-bar
  height: 2px !important
</style>
