<template lang="pug">
  span(@click="passTurn"
      class="btn btn-sm btn-success mr-2"
      v-if="this.isCurrentPlayer" style="cursor: pointer") Passa
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isCurrentPlayer'])
  },
  methods: {
    passTurn() { this.$store.dispatch('pass') }
  }
}
</script>
