<template lang="pug">
  transition(name='fade')
    .bg-label#notification(v-if='show')
      span.text-dark È il tuo turno 
      span.text-dark(v-if='fewCards') ({{deckCardsQuantity}} carte rimanenti)
      span.text-dark(v-if='oneCard') ({{deckCardsQuantity}} carta rimanente)
      span.text-dark(v-if='noCard') (carte finite)
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    deckCardsQuantity: { type: Number, required: true }
  },
  computed: {
    ...mapGetters(['isCurrentPlayer']),
    fewCards() {
      return this.deckCardsQuantity < 4 && this.deckCardsQuantity > 1
    },
    oneCard() {
      return this.deckCardsQuantity === 1
    },
    noCard() {
      return this.deckCardsQuantity === 0
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    isCurrentPlayer(newValue) {
      if(newValue) {
        this.show = true
        let _this = this
        setTimeout( function(){
          _this.show = false 
        }, 2000)
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang="sass">
#notification
  position: absolute
  z-index: 1000
  top: 50%
  left: 50%

.bg-label
  background-image: url("/card/label.png")
  background-size: cover
  background-position: center
  text-align: center
  padding-top: 30px
  height: 92px
  width: 320px
  margin-left: -125px
  margin-top: -40px  
</style>
