export default {
  addToNotification(state, payload) {
    state.notifications.push(payload)
  },
  clearBattleMessage(state) {
    state.battleMessage = null
  },
  displayOpponentMessage(state, payload) {
    state.opponent.message = payload
  },
  displayPlayerMessage(state, payload) {
    state.player.message = payload
  },
  setBattleMessage(state, payload) {
    state.battleMessage = payload
  },
  //  viene richiamato da bettle_channel.js alla ricezione di nuovi dati
  setLoading(state) {
    state.loading = true
  },
  endMatch(state) {
    state.gameOver = true

    // cancello intervalli
    clearInterval(state.timerInterval)
    state.timerInterval = null

    state.battleActions = []
    state.battleMessage = undefined
    state.currentPlayer = false
    state.currentUser = null
    state.events = []
    state.icons = []
    state.initializedMatch = []
    state.loaded_data = false
    state.loaded_images = false
    state.loading = false
    state.magnify = null
    state.magnifyEnabled = true
    state.matchesList = []
    state.mousePos = { x: 0, y: 0 }
    state.notifications = []
    state.opponent = {
      life: 0,
      mana: 0,
      message: null,
      board: [],
      hand: [],
    }
    state.player = {
      deckCardsQuantity: 40,
      life: 0,
      mana: 0,
      message: null,
      board: [],
      hand: [],
    }
    state.timer = {
      start: null,
      end: null,
      range: null,
      remaining_time: null
    }
    state.timerInterval = null
    state.turn = 0
  },
  setMatchId(state, id) {
    state.matchId = id
  },
  // viene richiamato in battle_channel.rb -> metodo load
  setState(state, payload) {
    state.turn = payload.turn
    state.battleActions = payload.battleActions
    state.currentPlayer = payload.currentPlayer
    state.events = payload.events
    state.loading = false
    state.player = payload.player
    state.opponent = payload.opponent
    state.gameOver = payload.gameOver
    state.icons = payload.icons
    state.timer = payload.timer
  },
  timerDecrease(state) {
    let timerData = state.timer
    timerData.remaining_time = (timerData.remaining_time - 1)
  },
  timerClear(state) {
    clearInterval(state.timerInterval)
    state.timerInterval = null
  },
  timerIntervalSet(state, payload) {
    state.timerInterval = payload
  },
  // Mutazione scatenata dal giocatore corrente
  togglePlayerHighlightedCard(state, data) {
    const player = this.getters.playerFromId(data.playerId)
    const cardList = player[data.deck]
    const card = cardList.find((card) => {
      return card.id == data.id
    })
    if (card != undefined) {
      card.highlighted = data.state
      return card
    }
    console.log(`Non trovo la card con questi dati: ${data.id} all'interno della lista cardList: ${cardList}`)
  },
  // Mutazione scatenata dall'avversario
  toggleOpponentHighlightedCard(state, data) {
    const player = this.getters.playerFromId(data.playerId)
    const cardList = player[data.deck]
    const card = cardList.find((card) => {
      return card.id == data.id
    })
    card.transparent = data.state
    return card
  },
  setMagnifyData(state, data) {
    state.magnify = data
  },
  removeMagnify(state) {
    state.magnifyEnabled = false
  }
}
