<template lang="pug">
  .row.m-0.p-0.py-3(v-intro='tutorialInfo')
    .col
    .col
      .text-center.mx-2.mx-sm-4
        points-life(:points="lifePoints" :role='role')
        playerMessenger(v-if="this.role == 'player'")
        opponentMessenger(v-else-if="this.role == 'opponent'")
    .col(@click="clicked"
          style="cursor: pointer"
          ref='center')
      .text-center
        span.p-2.player-target(:class="border")
          transition(appear name="fadeDown")
            img.mr-2.rounded-circle(:src="this.avatar"
                                    :class="cssClass"
                                    style="height: 25px; width: 25px;")
          transition(appear name="fadeUp")
            small {{this.currentPlayerFromRole().displayName}}
    .col
      .text-center.mx-2.mx-sm-4
        points-mana(:points="this.currentPlayerFromRole().mana" :role='role')
    .col
      div(v-if="this.role == 'opponent'")
        btn-options
        tutorial
</template>

<script>
import { mapGetters } from 'vuex'
import OpponentMessenger from './opponent_messenger.vue'
import PlayerMessenger from './player_messenger.vue'
import PointsLife from './points/life.vue'
import PointsMana from './points/mana.vue'
import BtnOptions from './board/btn_options.vue'
import bounceAnimation from './mixins/bounce_animation'
import sounds from './mixins/sounds'
import Tutorial from './tutorial'

export default {
  components: {
    BtnOptions,
    OpponentMessenger,
    PlayerMessenger,
    PointsLife,
    PointsMana,
    Tutorial
  },
  mixins: [bounceAnimation, sounds],
  props: {
    avatar: { type: String, required: true },
    player: { type: Object },
    // i ruoli possono essere player oppure opponent
    role: { type: String, required: true }
  },
  data() {
    return {
      cssClass: ''
    }
  },
  computed: {
    ...mapGetters(['playerFromRole', 'playerLife', 'isCurrentPlayer']),
    lifePoints() {
      return this.currentPlayerFromRole().life
    },
    border() {
      if (this.isCurrentPlayer && this.player.clickable) {
        return 'clickable'
      } else {
        return ''
      }
    },
    message() {
      if (this.isCurrentPlayer == false) {
        return 'Attendi il tuo turno.'
      }
      if (this.player.states.includes('blocked_by_wall')) {
        return 'Devi attaccare prima le carte "muro".'
      }
      return 'Non può essere selezionato.'
    },
    tutorialInfo() {
      if (this.role == 'opponent'){
        return `Questa è la barra del tuo <strong class='text-danger'>avversario</strong>.<br>
                Quando vuoi colpirlo devi cliccare qui.`
      }
      if (this.role == 'player'){
        return `Questa è la <strong class='text-success'>tua barra</strong>.<br>
                All'interno troverai indicato i
                <strong class='text-danger'>punti vita</strong> e i
                <strong class='text-info'>schei</strong> a tua disposizione.`
      }
    }
  },
  watch: {
    lifePoints() {
      this.applyEffects()
    }
  },
  methods: {
    applyEffects() {
      this.cssClass = 'hit'
      const _this = this
      this.timeout = setTimeout(() => {
        _this.cssClass = ''
      }, 300)
    },
    currentPlayerFromRole() {
      return this.playerFromRole(this.role)
    },
    clicked() {
      this.bounce(this.$refs.center)
      if (this.isCurrentPlayer && this.player.clickable) {
        const eventsInGame = this.$store.state.events.length
        // this.hitten = true
        if (eventsInGame > 0) {
          this.$store.dispatch('eventOnPlayer', {
            targetPlayerId: this.player.id
          })
        } else if (this.role === 'opponent') {
          this.$store.dispatch('attackOpponent')
        }
      } else {
        this.$store.dispatch('forbiddenClickOnPlayer', this.message)
      }
    }
  }
}
</script>

<style lang='sass'>
.player-target
  transition: box-shadow 0.3s ease-in-out
  border-radius: 3px
</style>
