// define a mixin object
const mixin = {
  methods: {
    audioPath() {
      let cardName = this.card.name
      cardName = cardName.replace(/\s/g, '-')
      const filename = cardName.toLowerCase()
      return `/audio/${filename}.mp3`
    },
    defaultAudioPath() {
      return '/audio/default.mp3'
    },
    playAudioFromCardName() {
      let audio
      let path = this.audioPath()
      if (this.existFile(path)) {
        audio = new Audio(path)
      } else {
        audio = new Audio(this.defaultAudioPath())
      }
      if (this.$store.state.sound) audio.play()
    },
    playAudioOffense() {
      const audio = new Audio('/audio/avatar/smash.mp3')
      if (this.$store.state.sound) audio.play()
    },
      playAudioDefense() {
      const audio = new Audio('/audio/avatar/gain.mp3')
      if (this.$store.state.sound) audio.play()
    },
    existFile(path) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', path, false)
      xhr.send()
      return xhr.status === 200
    }
  },

}

export default mixin
