export default {
  props: {
    card: { type: Object, required: true }
  },
  computed: {
    opacity() {
      if (this.card.transparent) {
        return '0.5'
      }
      return '1'
    }
  }
}