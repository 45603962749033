<template lang="pug">
  .card(v-if="graveYardExist")
    .card-header
      | Cimitero
    .card-body
      ul.list-container.list-unstyled.mb-0
        li(v-for="(element, index) in this.player.graveyard")
          | {{element.name}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    graveYardExist() {
      return false // per il momento disabilitiamolO!
      if (this.player.graveyard === undefined || this.player.graveyard.length === 0) {
        return false
      }
      return true
    },
    ...mapState(['player'])
  }
}
</script>

<style lang="sass" scoped>
.card
  .card-body
    font-size: 80%
    .list-container
      font-size: 80%
      max-height: 200px
      overflow-x: auto
</style>
