<template lang="pug">
  div
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  methods: {
    updatePositions(event){
      const posStage = document.getElementById('js-lobby')
                               .getBoundingClientRect()

      const scale = this.$store.state.scale
      let eventX = event.clientX
      let eventY = event.clientY
      if(isMobile){
        eventX = event.touches[0].clientX
        eventY = event.touches[0].clientY
      }
      const posX = (eventX-posStage.left)/scale
      const posY = (eventY-posStage.top)/scale

      this.$store.state.mousePos.x = posX
      this.$store.state.mousePos.y = posY
    }
  },
  mounted(){
    const rootEl = document.getElementById('js-lobby')
    let event = 'mousemove'
    if(isMobile) {
      event = 'touchmove'
    }
    rootEl.addEventListener(event, e => {
      this.updatePositions(e)
    })
  }
}
</script>
