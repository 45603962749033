export default {
  methods: {
    setupSound() {
      const sound = new Howl({
        src: [this.audioFile()],
        autoplay: true,
        onplayerror: function() {
          console.log('error')
          sound.once('unlock', function() {
            console.log('unlock')
            sound.play();
          });
        }
      });
    },
    audioFile() {
      if (this.status == 'victory') {
        return '/audio/ui.mp3'
      } else if (this.status == 'defeat') {
        return '/audio/ue.mp3'
      }
    }
  }
}
