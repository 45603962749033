export default {
  data() {
    return {
      fontColorR: 0,
      fontColorG: 0,
      fontColorB: 255,
      tweenedFontColorR: 0,
      tweenedFontColorG: 0,
      tweenedFontColorB: 0,
    }
  },
  computed: {
    cssClass() {
      if (this.status === 'victory') {
        return `color:rgb(${this.tweenedFontColorR},${this.tweenedFontColorG},${this.tweenedFontColorB})`
      }
      return `color:rgb(${this.tweenedFontColorR},${this.tweenedFontColorG},${this.tweenedFontColorB})`
    }
  },
  methods: {
    setTitleColor(){
      if (this.status == 'victory') {
        this.fontColorR = 255
        this.fontColorG = 255
        this.fontColorB = 0
      } else {
        this.fontColorR = 255
        this.fontColorG = 0
        this.fontColorB = 0
      }
    }
  },
  watch: {
    fontColorR(newValue) {
      TweenLite.to(this.$data, 2, {
        tweenedFontColorR: newValue,
        ease: Power3.ease
      })
    },
    fontColorG(newValue) {
      TweenLite.to(this.$data, 2, {
        tweenedFontColorG: newValue,
        ease: Power3.ease
      })
    },
    fontColorB(newValue) {
      TweenLite.to(this.$data, 2, {
        tweenedFontColorB: newValue,
        ease: Power3.ease
      })
    }
  }
}
