<template lang='pug'>
  div
    .text-center
      | Posizione in classifica {{userRanking}}
    .text-center
      | Posizione in classifica avversario {{opponentRanking}}
    a.mt-4.btn.btn-warning(href="/")
      | Avanti
</template>

<script>
  export default {
    props: {
      opponentRanking: { type: Number },
      userRanking: { type: Number }
    }
  }
</script>
