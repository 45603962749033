<template lang='pug'>
#turn-container
  div(style="position: absolute; left: 140px")
    event-card(v-for="(card, index) in this.$store.state.events"
               v-bind:key = "index + 1" 
               v-bind:card = "card")
  .disable-select(style="position: absolute; left: 340px; top: -30px")
    | Turno ( {{this.$store.state.turn}} )
  div(style="position: absolute; left: 340px")
    timer
  div(style="position: absolute; right: 140px; top: -8px")
    btn-pass
</template>
<script>
import BtnPass from './btn_pass.vue'
import Timer from './timer.vue'
import EventCard from './../event_card.vue'

export default {
  components: {
    BtnPass,
    EventCard,
    Timer
  }
}
</script>

<style lang="sass">
#turn-container
  position: absolute
  top: 295px
  z-index: 10
  width: 100%
</style>
