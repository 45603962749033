<template lang="pug">
.card.card-board.text-white(v-touch:start="touchStart"
                            v-touch:end="touchEnd"
                            @click="cardClicked"
                            @mouseenter="mouseover"
                            @mouseleave="mouseout"
                            :class="[borderStyle, stateEffects]"
                            ref='card'
                            :style="this.cardAndBackgroundStyle")
  card-pop-over(:card="card" ref="popover")
  .text-center.bonus-text(v-if="this.card.bonusStrength")
    span(v-bind:class="bonusClass(this.card.bonusStrength)")
      | {{ this.bonusWithSymbol(this.card.bonusStrength)}} &nbsp;
    | / &nbsp;
    span(v-bind:class="bonusClass(this.card.bonusConstitution)")
      | {{ this.bonusWithSymbol(this.card.bonusConstitution)}}
  small.p-1.card-little-container(v-if="this.card.constitution != undefined")
    .float-left
      .bg-dark.rounded-circle.p-2
        span(v-bind:class="bonusClass(recievedStrengthBonus())") {{ card.strength }}
        span.ml-1
          img.img-align-for-icon(:src="this.$store.state.icons.strength" height="11")
    .float-right
      .bg-dark.rounded-circle.p-2
        span(v-bind:class="bonusClass(recievedStrengthBonus())") {{ card.constitution }}
        span.ml-1
          img.img-align-for-icon(:src="this.$store.state.icons.constitution" height="11")
    .clearfix
</template>

<script>
import CardPopOver from '../card_pop_over.vue'

export default {
  props: {
    card: { type: Object, required: true },
  },
  components: { CardPopOver },
  computed: {
    message() {
      if (this.isCurrentPlayer === false) {
        return 'Attendi il tuo turno.'
      }
      if (this.card.attacked) {
        return 'Ha già attaccato.'
      }
      if (this.card.states.includes('blocked_by_wall')) {
        return 'Devi attaccare prima le carte "muro".'
      }
      return 'Non può essere selezionata.'
    }
  },
  methods: {
    bonusClass(bonus) {
      if (bonus > 0) {
        return 'text-green-4'
      }
      if (bonus < 0) {
        return 'text-red-4'
      }
      return ''
    },
    bonusSign(number) {
      if (number > 0) return '+'
      if (number < 0) return '-'
      return ''
    },
    bonusWithSymbol(number) {
      return this.bonusSign(number) + number.toString()
    },
    recievedConstitutionBonus() {
      return this.card.constution - this.card.originalConstitution
    },
    recievedStrengthBonus() {
      return this.card.strength - this.card.originalStrength
    },
    transitionName() {
      if (this.card.selected) {
        return 'bounce'
      }
      return 'fade'
    }
  }
}
</script>

<style scoped lang="sass">
  .card-board
    transition: box-shadow 0.3s ease-in-out
    background-repeat: no-repeat
    background-position: center
    background-size: 100x150
    .card-little-container
      position: absolute
      top: 0
      display: block
      width: 100%
  .selected
    box-shadow: 0 0 10px 7px #007BFF
  .highlighted
    box-shadow: 0 0 10px 7px #FFF
  .bonus-text
    color: white
    width: 300px
    height: 150px
    position: absolute
</style>
