<template lang="pug">
  .disable-select.text-right(style="position: relative;")
    span(v-intro="tutorialInfo")
      strong.mr-2(ref="points") {{ animatedPoints }}
      span.fas.fa-money-bill-alt(ref="icon")

</template>

<script>
import visualFx from '../mixins/visual_fx'

export default {
  props: {
    // i ruoli possono essere player oppure opponent
    role: {type: String, required: true}
  },
  mixins: [visualFx],
  data() {
    return {
      // sovrascrivo la proprietà del mixin visualFx
      gainColor: '#00c8c8', // colore acqua quando guadagni punti mana
      damageColor: '#00c8c8' // colore acqua  quando perdi mana
    }
  },
  watch: {
    points(newPoints, oldPoints) {
      this.visualFx_setup(newPoints, oldPoints)
    }
  },
  computed: {
    tutorialInfo(){
      if (this.role == 'player'){
        return `Sono i tuoi <strong class='text-info'>schei</strong>.<br>
                Per poter giocare le carte hai bisogno di <strong class='text-info'>schei</strong>.<br>
                Ogni turno hai a disposizione uno <strong>scheo</strong> in più.<br>`
      }
      if (this.role == 'opponent') {
        return `Sono i <strong class='text-info'>schei</strong> 
                a disposizione del tuo avversario.<br>
                Gli <strong class='text-info'>schei</strong> servono a giocare le carte.
                Fai attenzione a come spende i tuoi!`
      }
    }
  }
}
</script>
