<template>
  <div style="position: absolute;" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      channel: {}
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted() {
    const _this = this
    this.channel = App.cable.subscriptions.create({
      channel: 'MatchesChannel'
    },
    {
      connected() {
        console.log('connesso al match channel')
      },
      received(data) {
        if (data.status == 'initialization') {
          _this.$store.state.currentUser = data.current_user
        }
        _this.$store.state.initializedMatch = data.initialized_match
        _this.$store.state.matchesList = data.matches
      }
    })
  }
}
</script>
