import { render, staticRenderFns } from "./battle_core.vue?vue&type=template&id=24fad273&lang=pug&"
import script from "./battle_core.vue?vue&type=script&lang=js&"
export * from "./battle_core.vue?vue&type=script&lang=js&"
import style0 from "./battle_core.vue?vue&type=style&index=0&media=screen&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports