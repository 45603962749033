export default {
  methods: {
    createBattle() {
      $.ajax({
        url: `/matches/${this.matchId}/battle`,
        type: 'POST',
        dataType: 'json'
      })
    },
    // Annulla partita
    deleteMatch() {
      $.ajax({
        url: `/matches/${this.matchId}`,
        type: 'DELETE'
      }).done(() => {
        this.$router.push({ name: 'matches' })
      })
      // Risponde poi action_cable che viene gestito in mounted()
    },
    joinBot() {
      $.ajax({
        url: `/matches/${this.matchId}/join_bot`,
        type: 'POST',
        dataType: 'json'
      })
    },
    removeBot() {
      $.ajax({
        url: `/matches/${this.matchId}/join_bot`,
        type: 'DELETE',
        dataType: 'json'
      })
    },
    removeUser() {
      this.channel.unsubscribe()
      $.ajax({
        url: `matches/${this.matchId}/leave`,
        type: 'DELETE',
        data: { user_id: this.currentUser.id }
      }).done(() => {
        this.$router.push({ name: 'matches' })
      })
    },
    goToBattle(data) {
      // this.musicStop()
      this.$router.push({
        name: 'battle',
        params: {
          matchId: this.matchId,
          battleId: data.battle_id
        }
      })
    }
  }
}
