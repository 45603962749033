import { TimelineLite, TweenLite } from 'gsap/all'

export default {
  props: {
    points: { type: Number, required: true }
  },
  data() {
    // se vuoi cambiare opzioni sovrascrivi
    // questi data nel tuo componente
    return {
      tweenedPoints: 20,
      animationTime: 0.4,
      fontSizeTransform: '24px',
      damageColor: 'red',
      gainColor: 'green'
    }
  },
  computed: {
    // se vuoi animare altre ref nel tuo componente
    // basta sovrascrivere questo metodo
    $_visualFx_refsToAnimate() {
      return [this.$refs.points, this.$refs.icon]
    },
    $_visualFx_originalColor() {
      return getComputedStyle(this.$refs.icon).color
    },
    $_visualFx_originalFontSize() {
      return getComputedStyle(this.$refs.icon).fontSize
    },
    animatedPoints() {
      return this.tweenedPoints.toFixed(0)
    }
  },
  created() {
    this.tweenedPoints = this.points
  },
  methods: {
    // Si può richiamare tramite un watcher.
    visualFx_setup(newPoints, oldPoints) {
      TweenLite.to(this.$data, this.animationTime, { tweenedPoints: newPoints });
      if (newPoints > oldPoints) {
        this.$_visualFx_gain()
      } else {
        this.$_visualFx_damage()
      }
    },

    // **$_visualFx** è una convenzione suggerita dalle best practices di vue
    // per evidenziare i metodi private ed evitare di sovrascriverli per errore
    // https://vuejs.org/v2/style-guide/#Private-property-names-essential
    $_visualFx_damage() {
      const to = { color: this.damageColor }
      const back = { color: this.$_visualFx_originalColor }
      const tl = new TimelineLite()
      tl.to(this.$_visualFx_refsToAnimate, this.animationTime, to)
        .to(this.$_visualFx_refsToAnimate, this.animationTime, back)
    },

    // **$_visualFx** vedi commento precedente
    $_visualFx_gain() {
      const to = { color: this.gainColor }
      const back = { color: this.$_visualFx_originalColor }
      const tl = new TimelineLite();
      tl.to(this.$_visualFx_refsToAnimate, this.animationTime, to)
        .to(this.$_visualFx_refsToAnimate, this.animationTime, back)
    }
  }
}
