import { render, staticRenderFns } from "./graveyard.vue?vue&type=template&id=a9e331b6&scoped=true&lang=pug&"
import script from "./graveyard.vue?vue&type=script&lang=js&"
export * from "./graveyard.vue?vue&type=script&lang=js&"
import style0 from "./graveyard.vue?vue&type=style&index=0&id=a9e331b6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9e331b6",
  null
  
)

export default component.exports