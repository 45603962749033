import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Turbolinks from 'turbolinks'
import Rails from 'rails-ujs'
import Vue from 'vue/dist/vue.esm'
import Vue2TouchEvents from 'vue2-touch-events'
// Quando import bootstrap vue
// in console.log mi viene detto per due volte
// che ho avviato vue.
import BootstrapVue from 'bootstrap-vue'
import { mapState } from 'vuex'
import VueRouter from 'vue-router'
import VueIntro from 'vue-introjs'
import VueDragscroll from 'vue-dragscroll'

import TurbolinksAdapter from 'vue-turbolinks'
import ActionCable from 'actioncable'

import { isMobile } from 'mobile-device-detect'
import subscribeToMatches from 'channels/matches_channel'

// Shop
import CardShop from '../components/shops/card_shop.vue'
import LabelFiga from '../components/label_figa.vue'

// Match
import Lobby from '../components/matches/lobby'
import Matches from '../components/matches/matches'
import ActionCableManager from '../components/matches/action-cable-manager'

// Battle
import Battle from '../components/battles/battle.vue'
import Result from '../components/results/result.vue'
import setupStore from '../components/battles/store/index'

import Instructions from '../components/instructions/instructions'

import 'css/application.sass'

// tutorial for game
import 'intro.js/introjs.css'
import 'intro.js/themes/introjs-modern'


Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(VueIntro)
Vue.use(VueRouter)
Vue.use(VueDragscroll)

Vue.use(Vue2TouchEvents, {
  disableClick: isMobile
})

Rails.start()
Turbolinks.start()

function setupShop() {
  const vueShops = document.getElementById('vue-shops')
  if (vueShops != null) {
    new Vue({
      el: vueShops,
      components: { 'Card': CardShop, LabelFiga }
    })
  }
}

function setupInstructions() {
  const vueInstructions = document.getElementById('vue-instructions')
  if (vueInstructions != null) {
    new Vue({
      el: vueInstructions,
      components: { Instructions }
    })
  }
}


function setupRouting() {
  if ($('#js-entry-point').length > 0) {
    console.log('setupRouting')
    const routes = [
      {
        path: '/list',
        component: Matches,
        name: 'matches'
      },
      {
        path: '/instructions',
        component: Instructions,
        name: 'instructions'
      },
      {
        path: '/:matchId/lobby',
        component: Lobby,
        name: 'lobby'
      },
      {
        path: '/:matchId/battle/:battleId',
        component: Battle,
        name: 'battle'
      },
      {
        path: '/:matchId/result',
        component: Result,
        name: 'result'
      }
    ]

    const router = new VueRouter({ routes })


    const store = setupStore()
    window.store = store

    const battleApp = new Vue({
      router,
      store,
      components: {
        ActionCableManager,
        Battle,
        Lobby,
        Matches,
        Result
      },
      computed: mapState(['gameOver']),
      watch: {
        gameOver(newValue) {
          if (newValue == true) {
            this.$router.push({ name: 'result' })
          }
        }
      },
      mounted() {
        $.get('/users_status', (response) => {
          if (response.match_initialized_id) {
            this.$router.push({
              name: 'lobby',
              params: {
                matchId: response.match_initialized_id
              }
            })
          } else if (response.match_started_battle_id) {
            this.$router.push({
              name: 'battle',
              params: {
                battleId: response.match_started_battle_id,
                matchId: response.match_started_id
              }
            })
          } else {
            this.$router.push({ name: 'matches' })
          }
        })
      }
    }).$mount('#js-entry-point')
  }
}

function setupMatch() {
  if ($('#js-matches').length) {
    subscribeToMatches()
  }
}

document.addEventListener('turbolinks:load', () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  setupInstructions()
  setupMatch()
  setupShop()
  setupRouting()
})

window.App = {}
App.cable = ActionCable.createConsumer();
