
<template lang="pug">
// ATTENZIONE FORSE QUESTO COMPONENTE NON SERVE E PUO ESSERE RIMOSSO: VERIFICARE!
transition(name="fade")
  div(v-if="show" style="position: absolute;") {{message}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    card: { type: Object, required: true },
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters(['isCurrentPlayer']),
    message() {
      if (this.isCurrentPlayer === false) {
        return 'Attendi il tuo turno'
      }
      if (this.card.attacked) {
        return 'Ha già attaccato'
      }
      return this.card.states
    }
  },
  watch: {
    show() {
      setTimeout(() => {
        this.show = false
      }, 2000);
    }
  }
}
</script>
