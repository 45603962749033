<template lang='pug'>
  div
    .text-center.mb-4
      .bordo-bello.bg-gray-2.py-5(style='max-width: 300px')
        a(class='btn btn-success'
          @click.prevent='createMatch')
          | Crea partita
    .card.card-default(v-if='matchesList.length')
      .card-header Partite disponibili
      table.table
        tbody
          div(v-for='match in matchesList')
            single(:match='match'
                   :current-user='currentUser')

</template>

<script>
import { isMobile } from 'mobile-device-detect'
import { mapState } from 'vuex'
import Single from './single'

export default {
  components: {
    Single
  },
  computed: {
    ...mapState([
      'currentUser',
      'matchesList',
      'initializedMatch'
    ])
  },
  methods: {
    createMatch() {
      this.setFullscreen()
      $.ajax({
        method: 'POST',
        url: '/matches'
      }).done((matchId) => {
        this.$store.commit('setMatchId', matchId)
        this.$router.push({
          name: 'lobby',
          params: { matchId }
        })
      })
    },
    setFullscreen() {
      const element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen()
      }
      if (isMobile) { screen.orientation.lock('landscape') }
    }
  }
}
</script>
