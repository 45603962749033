export default {
  data() {
    return {
      imgSrc: false,
      imgLoaded: false,
      loading: true
    }
  },
  methods: {
    setupImage() {
      const key = '9CS5EOSJG2DA'
      $.get(`https://api.tenor.com/v1/random?key=${key}&q=${this.status}`)
       .done( response => {
         let result = response.results[0].media[0].mediumgif.url
         const img = new Image()
         img.src = result
         img.onload = () => {
           this.loading = false
           this.imgLoaded = true
         }
         this.imgSrc = result
       })
       .fail(function(a, b, c){
         console.log("NON SONO RIUSCITO A CARICARE LA GIF")
       })
     }
  }
}
