<template lang="pug">
  #opponent-hand.d-flex.flex-nowrap.py-3.justify-content-center(style="overflow-x: auto"
                                                  v-intro="'Questa è la mano del tuo avversario'")
    opponent-hand-card(v-for="(card, index) in this.$store.state.opponent.hand"
                        :key="card.id"
                       :card="card")
</template>

<script>
import OpponentHandCard from './opponent_hand_card'

export default {
  components: { OpponentHandCard }
}
</script>

<style lang="sass">
#opponent-hand
  position: absolute
  top: 0px
  left: 50%
  transform: translate(-50%, 0%)
</style>
