<template lang='pug'>
span.float-right.mr-2(@click='setupTutorial')
  i.fas.fa-question-circle(style="cursor: pointer")
</template>

<script>
export default {
  methods: {
    setupTutorial() {
      const intro = this.$intro()
      intro.setOption('overlayOpacity', 0.2);
      intro.setOption('showProgress', true);
      intro.setOption('nextLabel', 'Avanti')
      intro.setOption('prevLabel', 'Indietro')
      intro.setOption('skipLabel', 'Salta')
      intro.setOption('doneLabel', 'Finito')
      intro.start()
    }
  }
}
</script>

<style lang="sass">
.introjs-tooltip
  background-color: rgba(0,0,0,1) !important
.introjs-helperLayer
  background-color: rgba(255,0,0,0.1) !important
</style>
