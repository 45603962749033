<template lang='pug'>
  div
    .row.mb-5
      .col-sm-2
        img.img-fluid(src='/pupo-storto.png')
      .col
        h1.text-success MI STAI DICENDO CHE NON SEI CAPACE?!?!?!?!?!?!
        | Adesso ti insegno io a giocare!
    .mb-5
      life-points-mana
    div(style='margin-bottom: 100px')
      turn
    .mb-5
      generic-card
    .mb-5
      creature
    .mb-5
      creature-ability
    .mb-5
      event
    .mb-3
      event-ability
</template>

<script>
  import CreatureAbility from './creature-ability'
  import Creature from './creature'
  import EventAbility from './event-ability'
  import Event from './event'
  import GenericCard from './generic-card'
  import LifePointsMana from './life-points-mana'
  import Turn from './turn'

  export default {
    components: {
      CreatureAbility,
      Creature,
      EventAbility,
      Event,
      GenericCard,
      LifePointsMana,
      Turn
    }
  }
</script>

<style lang='sass'>
  .width-50
    width: 50px
    
  .width-20
    width: 20px
</style>
