<template lang="pug">
  #player-hand(v-dragscroll.x="true" 
               @dragscrollstart="dragStart" 
               @dragscrollend="dragEnd" 
               v-intro="tutorialInfo")
    player-hand-card(v-for="(card, index) in player.hand"
                     playerRole='player'
                     deck='hand'
                     :card="card"
                     :key = "card.id"
                     :position = "index"
                     :highlighted = "card.highlighted"
                     :dragging = "dragging")
</template>

<script>
import { mapState } from 'vuex'
import PlayerHandCard from './player_hand_card.vue'

export default {
  components: { PlayerHandCard },
  data() {
    return {
      dragging: false
    }
  },
  computed: {
    ...mapState(['player']),
    tutorialInfo(){
      return `Questa è la tua mano.<br> 
              Per poter giocare una carta devi avere abbastanza 
              <strong class='text-info'>schei</strong>. 
              Se le carte sono grige significa che non ne hai abbastanza. 
              Il costo della carta è indicata in alto a sinistra.`
    }
  },
  methods: {
    change() {

    },
    dragStart() {
      this.dragging = true
    },
    dragEnd() {
      this.dragging = false
    },
  }
}
</script>

<style lang="sass">
#player-hand
  bottom: 68px
  left: 0%
  position: absolute
  text-align: center
  width: 100%
  
  
</style>
