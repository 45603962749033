export default {
  battleActions: [],
  battleMessage: undefined,
  currentPlayer: false,
  currentUser: null,
  events: [],
  gameOver: false,
  icons: [],
  initializedMatch: [],
  loaded_data: false,
  loaded_images: false,
  loading: false,
  magnify: null,
  magnifyEnabled: true,
  matchesList: [],
  mousePos: { x: 0, y: 0 },
  notifications: [],
  opponent: {
    life: 0,
    mana: 0,
    message: null,
    board: [],
    hand: [],
  },
  player: {
    deckCardsQuantity: 40,
    life: 0,
    mana: 0,
    message: null,
    board: [],
    hand: [],
  },
  sound: true,
  timer: {
    start: null,
    end: null,
    range: null,
    remaining_time: null
  },
  timerInterval: null,
  turn: 0
}
