<template lang="pug">
  .card-hand(v-touch:start="touchStart"
             v-touch:end="touchEnd"
             v-touch:moving="moving"
             @mouseenter="showPopOver"
             @mouseleave="hideMagnify"
             :class="[card.rarity, card.genre, stateEffects, borderStyle]"
             :style="customPosition()"
             v-if="visible")
    player-hand-card-body(v-bind:card="this.card")
</template>

<script>

import sound from './mixins/sounds'
import highlight from './mixins/highlight'
import player from './board/mixins/player'
import { mapState, mapGetters } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import PlayerHandCardBody from './player_hand_card_body'

export default {
  components: { PlayerHandCardBody },
  mixins: [sound, highlight, player],
  props: {
    card: { type: Object, required: true },
    position: { type: Number, required: true },
  },
  data() {
    return {
      selectedCard: false,
      visible: true
    }
  },
  computed: {
    stateEffects() {
      if (this.isCurrentPlayer === false)
        return 'grayscale'
      if (this.card.playable === false)
        return 'grayscale'
      return ''
    },
    borderStyle() {
      if (this.card.highlighted)
        return 'card-highlighted'
      return ''
    },
    ...mapGetters(['isCurrentPlayer']),
    ...mapState(['mousePos'])
  },
  methods: {
    customPosition() {
      if (this.selectedCard) {
        const halfWidth = this.$el.offsetWidth/2
        const halfHeight = this.$el.offsetHeight/2
        const parentTop = this.$el.parentNode.offsetTop
        const top = this.mousePos.y-halfHeight-parentTop
        const left = this.mousePos.x-halfWidth
        return `position: absolute;
                left: ${left}px;
                top: ${top}px;
                z-index: 3000;
                cursor: pointer`
      }
      return 'cursor: pointer'
    },
    checkAreaAndPlay(event) {
      if (this.mousePos.y > this.$el.parentNode.offsetTop) {
        this.selectedCard = false
      } else {
        this.selectedCard = false
        this.visible = false
        this.playCard()
      }
    },
    playCard() {
      if (this.canBePlayed()) {
        this.playAudioFromCardName()
        this.$store.dispatch('play', { id: this.card.id })
      }
    },
    touchStart(event){
      if(this.canBePlayed()) {
        this.selectedCard = true
      }
      this.showPopOver(event)
    },
    touchEnd(event){
      this.selectedCard = false
      this.hideMagnify()
      if (this.canBePlayed()) {
        this.checkAreaAndPlay(event)
      }
    },
    moving(event){
      this.showPopOver(event)
    },
    showPopOver(event){
      this.showMagnify()
    },
    canBePlayed() {
      return this.isCurrentPlayer && this.card.playable // && this.dragging === false
    },
    calcX(){
      const el = this.$el
      return el.offsetLeft + el.parentNode.offsetLeft
    },
    calcY(){
      const el = this.$el
      const parentNode = el.parentNode
      const elY = el.offsetTop
      const parentElY = parentNode.offsetTop
      const result = elY + parentElY
      return result
    },
    showMagnify(){
      this.$store.dispatch('magnifyOn', {
        el: this.$el,
        card: this.card,
        posX: this.calcX(),
        posY: this.calcY(),
        position: 'top'
      })
    },
    hideMagnify(){
      this.$store.dispatch('magnifyOn', { card: null })
    },
  },
}
</script>

<style scoped lang="sass">
  .card-highlighted
    box-shadow: 0 0 10px 7px #FFF
</style>
