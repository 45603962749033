<template lang="pug">
#sidebar(v-if="false")
  history.mb-4
  .row.mb-2
    .col-sm-12.col-xl-6
      deck(:deckCardsQuantity='this.$store.state.player.deckCardsQuantity')
    .col-sm-12.col-xl-6
      graveyard
  tutorial
</template>

<script>
import History from './history'
import Deck from './deck'
import Graveyard from './graveyard'
import Tutorial from './tutorial'

export default {
  components: {
    Graveyard,
    History,
    Deck,
    Tutorial
  },
}
</script>


<style lang="sass">
#sidebar
  position: absolute
  top: 80px
  right: 20px
  width: 200px
</style>
