<template lang='pug'>
  .container.mt-4(style="max-width: 620px")
    .row.justify-content-center
      .col.text-center.bordo-bello(v-for='badge in this.badges')
        .p-5
          img.img-fluid(src='/badgesx.png')
          | {{badgeName(badge)}}
          hr
          | {{badgeDescription(badge)}}
    .row.justify-content-center
      .col.text-center.bordo-bello(v-for='mission in this.missions')
        .p-5
          img.img-fluid(src='/badgedx.png')
          | {{missionName(mission)}}
          hr
          | {{missionDescription(mission)}}
</template>

<script>
export default {
  props: {
    badges: { type: Array },
    missions: { type: Array }
  },
  methods: {
    badgeName(badge) {
      return `Hai sbloccato ${badge.name}`
    },
    badgeDescription(badge) {
      return badge.description
    },
    missionName(mission) {
      return `Hai completato ${mission.name}`
    },
    missionDescription(mission) {
      return mission.description
    }
  }
}
</script>
