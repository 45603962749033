<script>
import { mapGetters, mapState } from 'vuex'

import boardCardTemplate from './mixins/board_card_template.vue'
import bounceAnimation from '../mixins/bounce_animation'
import cardStyle from './mixins/card_style'
import cardOpacity from '../mixins/card_opacity'
import highlight from '../mixins/highlight'
import player from './mixins/player'
import sounds from '../mixins/sounds'

export default {
  mixins: [
    boardCardTemplate,
    bounceAnimation,
    cardOpacity,
    cardStyle,
    highlight,
    player,
    sounds,
  ],

  computed: {
    stateEffects() {
      return ''
    },
    cardAndBackgroundStyle() {
      return {
        opacity: this.opacity,
        'background-image': `url('${this.card.imgCardBoard}')`,
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'background-size': '100x150',
        'cursor': 'pointer'
      }
    },
    ...mapGetters(['isCurrentPlayer']),
    ...mapState(['events'])
  },
  methods: {
    cardClicked() {
      this.bounce(this.$refs.card)
      this.hideMagnify()
      if (this.isCurrentPlayer && this.card.clickable) {
        this.handleCardAsTarget()
      } else {
        this.$store.dispatch('forbiddenClickOnCard', this.message)
      }
    },
    handleCardAsTarget(){
      this.toggleSelected()
      this.setAsTargetForEventOrAttacker()
    },
    setAsTargetForEventOrAttacker(){
      if (this.events.length > 0) {
        this.triggerEventOnCard()
      } else {
        this.cardIsDefender()
      }
    },
    cardIsDefender(){
      this.$store.dispatch('selectForDefense', { id: this.card.id })
    },
    triggerEventOnCard(){
      this.$store.dispatch('eventOnCard', { targetCardId: this.card.id })
    },
    toggleSelected(){
      if (this.card.selected == true) {
        this.card.selected = false
      } else  {
        this.card.selected = true
      }
    },
    mouseover(e){
      this.showMagnify()
    },
    mouseout(e){
      this.hideMagnify()
    },
    touchStart(e){
      this.showMagnify()
    },
    touchEnd(e){
      this.hideMagnify()
    },
    calcX(){
      const el = this.$el
      return el.offsetLeft  + el.parentNode.offsetLeft
    },
    calcY(){
      const el = this.$el
      const parentNode = el.parentNode
      const result = el.offsetTop + 
                     el.clientHeight +
                     parentNode.parentNode.offsetTop
      return result
    },
    showMagnify(){
      this.$store.dispatch('magnifyOn', {
        el: this.$el,
        card: this.card,
        posX: this.calcX(),
        posY: this.calcY(),
        position: 'bottom'
      })
    },
    hideMagnify(){
      this.$store.dispatch('magnifyOn', { card: null })
    },
  }

}
</script>
