import { render, staticRenderFns } from "./player_hand_card_body.vue?vue&type=template&id=24c6839b&lang=pug&"
import script from "./player_hand_card_body.vue?vue&type=script&lang=js&"
export * from "./player_hand_card_body.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports