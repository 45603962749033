import { mapGetters } from 'vuex'

export default {
  props: {
    card: { type: Object, required: true },
  },
  computed: {
    borderStyle() {
      if (this.card.selected) {
        return 'selected'
      }
      if (this.card.highlighted) {
        return 'highlighted'
      }
      if (this.isCurrentPlayer && this.card.clickable) {
        return 'clickable'
      }
      return ''
    },
    ...mapGetters(['isCurrentPlayer']),

  }
}
