<template lang="pug">
  #js-lobby(style="questo id sarebbe da cambiare perchè qui è sbagliato e pericoloso")
    div(:style="this.bg_style")
      history
      loading-cursor
      magnify
      transition(name="fade")
        .text-center(v-if="loading" class="loading" style="position: absolute; top: 50%; left: 50%")
          .pt-5 Loading
      transition(name="fade")
        div(v-if="error")
          | {{ error }}
      transition(name="fade")
        div(v-if="battle")
          line-helper(v-if="drawLine")
          .battle-header
            avatar(role='opponent' key='opponent'
                         :avatar="this.opponentAvatar"
                         :player="this.$store.state.opponent")
          battle-core
          sidebar
          .battle-footer
            avatar(role="player" key='player'
                   :avatar="this.playerAvatar"
                   :player="this.$store.state.player")

</template>

<script>
import Avatar from './avatar'
import BattleCore from './battle_core'
import Graveyard from './graveyard'
import LineHelper from './line_helper'
import LoadingCursor from './loading_cursor'
import Magnify from './magnify'
import { mapGetters } from 'vuex'
import Sidebar from './sidebar'
import History from './history'

export default {
  components: {
    Avatar,
    BattleCore,
    History,
    LineHelper,
    LoadingCursor,
    Magnify,
    Notification,
    Sidebar,
  },

  data() {
    return {
      opponentAvatar: '',
      playerAvatar: '',
      bg: '',
      trasto: '',
      loading: false,
      battle: false,
      error: null
    }
  },
  computed: {
    bg_style() {
      return {
        'background-image': `url('${this.bg}')`,
        'background-size': 'cover',
        'height': '100%'
      }
    },
    battleId() {
      return this.$route.params.battleId
    },
    drawLine(){
      let events = this.$store.state.events
      let eventsInGame = events.length
      if (eventsInGame) {
        return events[0].rules[0].targets.length > 0
      }
      return true
    },
    ...mapGetters(['loaded'])
  },
  watch: {
    $route: 'fetchData'
  },
  mounted() {
    this.fetchData()
    this.setupBattleChannel()
  },
  methods: {
    setupBattleChannel() {
      const _this = this
      App.battleChannel = App.cable.subscriptions.create(
        {
          channel: 'BattleChannel',
          battle: this.battleId
        },
        {
          initialized() {
            console.log('inizializzo')
          },
          connected() {
            console.log('Connesso al server!')
            App.battleChannel.perform('load')
          },
          rejected() {
            console.log('Rifiutato dal server!')
          },
          // WHEN RECEIVE DATA FROM SERVER
          received(response) {
            _this.$store.commit(response.mutation, response.data)
          },
          disconnected() {
            console.log('Disconnesso dal webserver!')
          },
        },
      )
    },
    fetchData() {
      this.battle = null
      this.error = null
      this.loading = true
      this.battle = false
      $.ajax({ url: `/battles/${this.battleId}.json`, type: 'GET' })
       .done((response) => {
         this.loading = false
         this.opponentAvatar = response.opponentAvatar
         this.playerAvatar = response.playerAvatar
         this.bg = response.bg
         this.trasto = response.trasto
         this.battle = true
      })
      .fail((response) => {
        console.log('errore: ', response)
        this.error = response
      })
    }
  }

}
</script>

<style lang='sass'>
#js-lobby
  position: absolute
  top: 50%
  left: 50%
  width: 1280px
  height: 720px
  transform-origin: center center
</style>
