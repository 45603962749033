<template lang="pug">
  .card-img-overlay(style="height: 15px; width: 15px")
    .float-left.margin-theme(style="background-color: rgba(0,0,0,0.5)")
      img(:src='icon' style="height: 15px; width: 15px")
</template>

<script>
export default {
  props: {
    icon: { type: String }
  }
}
</script>
