<template lang="pug">
  svg.maggico-svg(v-if="this.lineVisible")
    line.path#myLine
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect'

export default {
  computed: {
    ...mapGetters(['cardsSelected', 'currentCardSelected', 'isCurrentPlayer']),
    ...mapState(['scale', 'mousePos']),
    lineVisible() {
      if (isMobile === false) {
        return this.cardsSelected.length > 0 && this.isCurrentPlayer
      }
      return false
    }
  },
  mounted() {
    $(document).mousemove(() => {
      if (this.cardsSelected.length > 0) {
        this.drawSVG(this.currentCardSelected)
      }
    });
  },
  methods: {
    drawSVG(divId) {
      const div = document.getElementById(divId.replace('#', ''))
      let x1
      let y1
      const halfWidth = div.offsetWidth/2
      const halfHalfHeight = (div.offsetHeight/2) / 2
      const sumParentOffsetTop = div.parentNode.offsetTop + div.parentNode.parentNode.offsetTop
      if (divId == '#card-event') {
        x1 = div.offsetLeft + div.parentNode.offsetLeft + halfWidth
        y1 = div.offsetTop - halfHalfHeight + sumParentOffsetTop + div.parentNode.parentNode.parentNode.offsetTop
      } else {
        x1 = div.offsetLeft + halfWidth
        y1 = div.offsetTop + halfHalfHeight + sumParentOffsetTop
      }
      
      $('#myLine').attr('x1', x1)
                  .attr('y1', y1)
                  .attr('x2', this.mousePos.x)
                  .attr('y2', this.mousePos.y)
    }
  }
}
</script>
