<template lang='pug'>
  tr
    td {{ usersNames() }}
    td.float-right
      a.btn.btn-primary(v-if='canJoin'
                        @click.prevent='joinClick') Partecipa
      span.mr-2.text-secondary(v-else
                      @click.prevent='') Partita al completo
      a.btn.btn-primary(v-if='this.currentUser.admin'
                        @click.prevent='deleteClick') Rimuovi
</template>

<script>

import { isMobile } from 'mobile-device-detect'

export default {

  props: {
    currentUser: { type: Object, required: true },
    match: { type: Object, required: true }
  },
  computed: {
    deletePath() {
      return `matches/${this.match.id}`
    },
    joinPath() {
      return `/matches/${this.match.id}/join`
    },
    canJoin() {
      return (this.match.users.length < 2)
    }
  },
  methods: {
    joinClick() {
      this.setFullscreen()
      $.ajax({
        method: 'POST',
        url: this.joinPath
      }).done(() => {
        this.$router.push({
          name: 'lobby',
          params: { matchId: this.match.id }
        })
      })
    },
    deleteClick() {
      $.ajax({
        method: 'DELETE',
        url: this.deletePath
      }).done(() => {
        this.$router.push({ name: 'matches' })
      })
    },
    setFullscreen() {
        const element = document.documentElement
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) { /* Firefox */
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          element.webkitRequestFullscreen()
        } else if (element.msRequestFullscreen) { /* IE/Edge */
          element.msRequestFullscreen()
        }
        if (isMobile) { screen.orientation.lock('landscape') }
    },
    usersNames() {
      let names = ''
      this.match.users.forEach((user) => {
        names = names + user.name
      })
      return names
    }
  }
}
</script>
