<template lang='pug'>
  div
    img.shadow(:title='cardsQuantity' 
        src="/card/b_01-small.png"
        class='img-fluid'
        v-b-tooltip.hover.top)
    strong(v-if='lessThanFourCards') {{cardsQuantity}}
</template>

<script>

export default {
  props: {
    deckCardsQuantity: { type: Number, required: true }
  },
  computed: {
    moreThanOne() {
      return this.deckCardsQuantity > 1
    },
    cardsQuantity() {
      if(this.moreThanOne) {
        return `Ti restano ${this.deckCardsQuantity} carte nel mazzo`
      } else if(this.oneCard) {
        return `Ti resta ${this.deckCardsQuantity} carte nel mazzo`
      } else if(this.noCards) {
        return 'Hai finito le carte'
      }
    },
    lessThanFourCards() {
      return this.deckCardsQuantity < 4
    },
    noCards() {
      return this.deckCardsQuantity === 0
    },
    oneCard() {
      return this.deckCardsQuantity === 1
    },
  }
}

</script>

<style lang='sass'>

.margin-text
  padding-top: 135px
</style>
