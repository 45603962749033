export default {
  props: {
    playerRole: { type: String, required: true },
  },
  computed: {
    player() {
      if (this.playerRole === undefined || this.playerRole == null) {
        throw new Error('!!! Role non definito, verifica le PROPS')
      }

      const data = this.$store.state[this.playerRole]

      if (data === undefined) {
        throw new Error('!!! Player non definito - VERIFICA LO STORE SCIOCCHINO !!!')
      } else {
        return data
      }
    }
  }
}
