<template lang="pug">
  .card.card-retro(:style="{ opacity: opacity }")
    .card-body
</template>

<script>
import cardOpacity from './mixins/card_opacity'

export default {
  mixins: [cardOpacity],
  props: {
    card: { type: Object, required: true },
    transition: { type: String, default: 'slideLeft' }
  }
}
</script>
