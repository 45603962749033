<template lang="pug">
#gameContainer
  battle-message
  notification(:deckCardsQuantity='this.$store.state.player.deckCardsQuantity')
  opponent-hand
  .board-pattern
    opponent-board
  turn-container
  player-board.pb-4.mb-2
  player-hand
</template>

<script>
import BattleMessage from './battle_message.vue'
import Notification from './notification.vue'
import OpponentBoard from './board/opponent_board.vue'
import OpponentHand from './opponent_hand.vue'
import PlayerBoard from './board/player_board.vue'
import PlayerHand from './player_hand.vue'
import TurnContainer from './board/turn_container.vue'
export default {
  components: {
    BattleMessage,
    Notification,
    OpponentBoard,
    OpponentHand,
    PlayerBoard,
    PlayerHand,
    TurnContainer
  },
  mounted() {
    this.doResize()
    window.addEventListener('resize', this.doResize)
    this.hideNavbarAndFooter()
  },
  methods: {
    // TODO: da spostare in battle vue dove c'è il nodo js-lobby
    doResize() {
      const availableWidth = window.innerWidth
      const availableHeight = window.innerHeight
      const container = document.getElementById('js-lobby')
      const contentWidth = container.offsetWidth
      const contentHeight = container.offsetHeight
      let scale = Math.min(
        availableWidth / contentWidth,
        availableHeight / contentHeight
      )
      this.$store.state.scale = scale
      const transform = "transform: translate(-50%, -50%) scale(" + scale + ") !important"
      container.setAttribute('style', transform);
    },
    hideNavbarAndFooter() {
      const navbar = document.getElementById('navbar')
      navbar.classList.add('d-none')
      const footer = document.getElementById('footer')
      footer.classList.add('d-none')
      document.getElementsByTagName('body')[0].style.marginBottom = '0'
    }
  }
}
</script>

<style media="screen" lang="sass">
// indicazioni da
// https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/

html
  height: 100%
  // https://stackoverflow.com/questions/7049875/why-doesnt-height-100-work-to-expand-divs-to-the-screen-height

body
  height: 100%
  background-color: hsl(41, 12%, 18%) !important
</style>
