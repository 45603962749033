<template lang="pug">
  div
    .text-center.mt-5
      transition(name="fade")
        div(v-if="error != null")
          div.mt-3 Ups abbiamo un errore
          pre(style="color: white; width: 300px; margin: 0 auto; text-align: left") {{ error }}
          a.mt-4.btn.btn-warning(href="/")
            | Avanti
      transition(name="fade")
        div
          transition(name='fade')
            div(v-if="show")
              h1(:style='cssClass') {{message}}
              p(:style='cssFontSize') Hai ricevuto {{animatedMoney}} schei
          transition(name="fade")
            div(v-if="loading")
              | Caricamento...
            div.mb-3(v-if="this.imgLoaded")
              img.shadow(v-bind:src='this.imgSrc' class="rounded")
              br
          transition(name="fade")
            div(v-if="show")
              ranking(:opponent-ranking='opponentRanking'
                      :user-ranking='userRanking')
    transition(name="fade")
      div(v-if="show")
        badges-missions(:badges='badges' 
                       :missions='missions')

</template>

<script>
import { Howl } from 'howler'
import { mapState } from 'vuex'
import {
  TweenLite,
  Elastic,
  Back,
  Power3
} from 'gsap/all'

import font from './font'
import money from './money'
import resultGif from './result-gif'
import sound from './sound'

import BadgesMissions from './badges-missions'
import Ranking from './ranking'

export default {
  components: { BadgesMissions, Ranking },
  mixins: [font, money, resultGif, sound],
  data() {
    return {
      badges: [],
      error: null,
      loading: true,
      missions: [],
      opponentRanking: null,
      retryToConnect: 0,
      show: false,
      status: null,
      userRanking: null
    }
  },
  computed: {
    message() {
      if (this.status === 'victory') {
        return 'HAI VINTO'
      }
      return 'HAI PERSO'
    },
    matchId() {
      return this.$route.params.matchId
    }
  },
  mounted() {
    this.fetchData()
    this.showNavbarAndFooter()
  },
  methods: {
    fetchData() {
      const resultPath = `${window.location.pathname}/${this.matchId}/result.json`
      $.ajax({ url: resultPath, type: 'GET' })
        .done((response) => {
          this.status = response.status
          this.show = true
          this.badges = response.badges
          this.missions = response.missions
          this.setUsersRanking(response)
          this.setMoney(response.moneyAdd)
          this.setTitleColor()
          this.setupImage()
          this.setupSound()
        })
        .fail((response) => {
          console.log('FALLLITO')
          if (response.responseJSON.statusCode == 'user_not_present') {
            setTimeout(() => {
              if (this.retryToConnect < 3) { 
                console.log('Utente non individuato nel match. Riprovo tra un secondo.')
                this.fetchData()
                this.retryToConnect += 1
              } else {
                console.log('Utente non individuabile dopo 5 tentativi')
                this.error = response
              }
            }, 1000)
          } else {
            this.error = response
          }
        })
    },
    setUsersRanking(response) {
      this.userRanking = response.userRanking
      this.opponentRanking = response.opponentRanking
    },
    showNavbarAndFooter() {
      const navbar = document.getElementById('navbar')
      navbar.classList.remove('d-none')
      const footer = document.getElementById('footer')
      footer.classList.remove('d-none')
      document.getElementsByTagName('body')[0].style.marginBottom = '60px'
    }
  }
}
</script>
