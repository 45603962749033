<template lang="pug">
  #loading-container(v-if="isCurrentPlayer && isLoading")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isCurrentPlayer', 'isLoading']),
  }
}
</script>

<style lang="sass">
#loading-container
  cursor: wait
  position: absolute
  width: 100%
  height: 100%
  z-index: 2000
</style>
